
import { defineComponent, onMounted } from "vue";
import { Form } from "vee-validate";
import TotalTable from "@/views/DashBoard/summary/TotalInstituteSummaryTable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
//import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default defineComponent({
  name: "new-entity-institution",
  components: {
    Form,
    TotalTable,
    //Field,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      id:this.$route.params.id,
      
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableData: [
        {
          sl: 1,
          ref_no: 1233,
        },
      ],
      form: {},
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
          rowspan: 2,
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Ref No.",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "NID",
          key: "NID",
          sortable: true,
        },
        {
          name: "BCN",
          key: "bcn",
          sortable: true,
        },
        {
          name: "Reg No#",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Birth Date",
          key: "birth_date",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Mobile",
          key: "mobile",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      courseData:{}
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    await this.getInstitute();
  },
  methods: {
    async formSubmit() {
      alert("Form Submitted!");
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      let data={
        entity_parent_id:this.id
      }
      await ApiService.post("dashboard/traininginstitute",data)
        .then((response) => {
          this.courseData = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Course Summary Association");
    });
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});


import { defineComponent, onMounted } from "vue";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
// import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
    data: Array
  },
  components: {
    // Dropdown1,
  },
 
  setup() {
  },
});
